.BodyContentTopInner {
}
.succesScreenAmount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.succesScreenAmount span:nth-child(1) {
  color: var(--mediumGray);
  font-size: 12px;
}
.succesScreenAmount span:nth-child(2) {
  color: var(--darkGray);
  font-size: 22px;
  font-weight: 500;
}
.succesScreenPhonenum {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.succesScreenPhonenum span:nth-child(1) {
  color: #4b4b4b;
  font-size: 1.2rem;
  padding: 0 5px;
}
.succesScreenPhonenum span:nth-child(2) {
  color: #27aae1;
  font-size: 1.2rem;
}
.succesScreentick {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0 20px;
}
.SuccestickIcon {
  background: url("../../../assets/images/Green_Checkmark.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 5px;
}
.succesScreenBillTagline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #565656;
  font-size: 1rem;
  text-align: center;
}
.succesScreentime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #565656;
  font-size: 0.7rem;
  text-align: center;
  font-weight: 300;
  text-transform: capitalize;
}
.succesScreenbottomMessage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 2px;
}
.succesScreenbottomMessage b {
  color: #27aae1;
  margin-left: 5px;
}
.succesScreenbottomMessage span:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.smsIcon {
  background: url("../../../assets/images/SMS.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 23px;
  height: 22px;
  display: inline-block;
  margin-right: 5px;
}
.succesScreenbottomMessage span:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #565656;
  font-size: 0.7rem;
  font-weight: 300;
}
.succesScreenbottomMessage span:nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #27aae1;
}
.successScreenList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px;
  padding: 15px 0;
  border-bottom: 1px solid var(--extraLightGray);
}
.borderBottomNo {
  border-bottom: none;
}
.successScreenListLeft {
  font-size: 12px;
  color: var(--mediumGray);
}
.successScreenListRight {
  font-size: 16px;
  color: var(--darkGray);
  text-transform: capitalize;
}
.circleBox {
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 12px;
}
.leftCircle {
  position: absolute;
  left: -16px;
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background: var(--white);
  border-radius: 50%;
  /* box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04); */
}
.rightCircle {
  position: absolute;
  right: -16px;
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  background: var(--white);
  border-radius: 50%;
}
.cashBackRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px;
  padding: 15px 0;
  border-top: 1px solid var(--extraLightGray);
}
.cashBackRowLeft {
  font-size: 12px;
  color: var(--mediumGray);
}
.cashBackRowRight {
  font-size: 16px;
  color: var(--brightGreen);
}
