.bodyContentpaymentmethod {
  float: left;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  padding: 90px 20px 30px;
}
.bodyContentpaymentmethod .BodyContentTop {
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  margin: 5px 0 20px;
  padding: 20px 15px 0px;
}
.bodyContentpaymentmethod .totalHeading {
  text-align: center;
  border-bottom: none;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.companyInstructions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px !important;
}
.totalHeading p {
  color: #565656;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
}
.contentHeading {
}
.contentHeading h3 {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  color: #414141;
  line-height: 30px;
  margin-bottom: 30px;
}
.instructionsList {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  margin: 5px 0 20px;
  padding: 20px 15px 10px;
  border-radius: 18px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.instructionsList ul {
  margin: 0;
  padding: 0;
}
.instructionsList li {
  list-style: none;
  display: flex;
  float: left;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
}
.instructionsList li:nth-child(5) {
  list-style: none;
  display: block;
  float: left;
  flex-direction: row;
  width: 100%;
}
.instructionsList li span:nth-child(1) {
  border: 1px solid #efefef;
  border-radius: 50%;
  padding: 0px 8px;
  height: 25px;
  width: 25px;
  float: left;
  font-size: 0.7rem;
  line-height: 23px;
  margin-right: 5px;
  color: #414141;
  margin-bottom: 10px;
  position: relative;
  top: -1px;
}
.instructionsList li span:nth-child(2) {
  color: #414141;
  font-size: 0.9rem;
  margin-left: 5px;
  float: left;
}
.instructionsList li ul {
  margin: -8px 0 0 34px !important;
  padding: 0;
  float: left;
}
.instructionsList li ul li {
  list-style: none;
  font-size: 0.7rem;
  color: #414141;
  margin: 5px 0 0;
}
.BodyContentMiddle {
  margin: 25px 0 20px !important;
  float: left;
  width: 100%;
}
.easypaisaLogo {
  float: left;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  text-align: center;
  padding: 0 30px;
}
.easypaisaLogo img {
  width: 100%;
}
