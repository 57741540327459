.VoucherHeaderFixed {
  align-items: center;
  position: fixed;
  line-height: 20px;
  padding: 27px 0;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: var(--white);
  box-shadow: none;
  -webkit-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
}

.UdhaarLogo {
  /* margin-left:-170px; */
  height: 30px;
  width: auto;
  cursor: pointer;
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
}
