.bodyContentpaymentmethod {
  float: left;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  padding: 90px 20px 30px;
}
.bodyContentpaymentmethod .BodyContentTop {
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  margin: 5px 0 20px;
  padding: 60px 5px 0px;
  width: 100%;
}
.bodyContentpaymentmethod .totalHeading {
  text-align: center;
  border-bottom: none;
  margin-bottom: 5px;
  padding-bottom: 0px;
}
.bodyContentpaymentmethod .totalHeading {
}
.bodyContentpaymentmethod .paymentMethodNewCont .totalHeading p {
  text-align: left;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.totalHeading p {
  color: #565656;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
}
.bodyContentpaymentmethod .paymentMethodNewCont .totalHeading {
  width: 50%;
  /* border: 1px solid; */
}
.companyLogoMainBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
.companyLogoMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto 10px;
  /* border: 1px solid;s */
}
.companyLogoMain.companyLogoMainUrdu {
  justify-content: flex-end;
}
.companyLogoMainInner {
  display: flex;
  flex-direction: row;
  float: left;
  width: 90px;
  background: transparent;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  padding: 0px;
  float: left;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  min-height: 70px;
  margin: 5px 0px 5px 0px;
}
.companyLogoMainInner:hover,
.companyLogoMainInner:hover img {
  cursor: pointer;
  opacity: 0.7;
}
.companyLogoMainInner img {
  width: 80%;
  opacity: 1;
  /* opacity: 0.7; */

  /* border-radius: 10px; */
}
.companyLogoMainInner.methodSelect {
  background: rgba(74, 198, 0, 0.15);
  border-radius: 10px;
}
.companyLogoMainInner.methodSelect img {
  opacity: 1;
}
.BodyContentMiddle {
  margin: 25px 0 20px !important;
  float: left;
  width: 100%;
}

.trustedIcon {
  background: url("../../../assets/images/100_Secure.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 34px;
  height: 34px;
  display: inline-block;
  margin-right: 5px;
}
.BodyContentMiddle.seeDetailCont {
  margin: 5px 0 30px !important;
}
.BodyContentMiddle.seeDetailCont .seeprocessButton {
  color: #27aae1;
  padding: 0;
  border: none;
  text-decoration: underline;
  box-shadow: none;
}
.cashBackPara {
  width: 100%;
  margin: -10px 0 15px;
}
.cashBackPara p {
  margin: 0;
  font-size: 0.9rem;
}
.teleCashbook {
  text-align: left;
  width: 100%;
  padding-left: 0px;
}
.jazzCashbook {
  text-align: center;
  width: 100%;
}
.radioLablelBox input {
  width: auto;
  display: inline-block;
  border: none;
}
/* .radioLablelBox label {
  position: relative;
  top: 0;
  left: 0;
  pointer-events: auto;
  color: var(--darkGray);
  font-size: 13px;
  cursor: pointer;
}
.radioLablelBox {
  float: left;
  width: 33.3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.radioLablelBox.radioLablelBoxUrdu {
  flex-direction: row-reverse;
}
.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px !important;
  transition: all 0.15s ease-out 0s;
  background: none;
  border: 2px solid var(--mainColor) !important;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: var(--lightGray);
}
.option-input:checked {
  background: var(--white);
}
.option-input:checked::before {
  height: 12px;
  width: 12px;
  position: absolute;
  content: "";
  display: inline-block;
  font-size: 27px;
  text-align: center;
  background: var(--mainColor);
  border-radius: 50px;
  top: 2px;
  left: 2px;
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50px;
}
.option-input.radio::after {
  border-radius: 50%;
} */
@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) {
  .companyLogoMainInner {
    width: 60px;
    min-height: 60px;
    padding: 5px;
    margin: 5px 0px 5px 10px;
  }
}
@media only screen and (min-device-width: 537px) and (max-device-width: 768px) {
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading {
    width: 60%;
  }
  .companyLogoMainInner {
    width: 60px;
    min-height: 60px;
    padding: 5px;
    margin: 5px 0px 5px 5px;
  }
  .companyLogoMainInner img {
    width: 100%;
  }
  .totalHeading p {
    font-size: 0.9rem !important;
  }
}
@media only screen and (min-device-width: 415px) and (max-device-width: 536px) {
  .bodyContentpaymentmethod .BodyContentTop {
    padding: 20px 5px 0px;
  }
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading {
    width: 100%;
  }
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading p {
    text-align: center;
    font-size: 1rem !important;
  }
  .companyLogoMainInner {
    width: 70px;
    min-height: 55px;
    padding: 5px;
    margin: 10px 5px 5px 8px;
  }
  .companyLogoMainInner img {
    width: 100%;
  }
  .totalHeading p {
    font-size: 0.8rem !important;
  }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 414px) {
  .bodyContentpaymentmethod .BodyContentTop {
    padding: 20px 5px 0px;
  }
  .companyLogoMain {
    width: 100%;
    justify-content: flex-start;
  }
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading {
    width: 100%;
  }
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading p {
    text-align: center;
    font-size: 1rem !important;
  }
  .companyLogoMainInner {
    width: 70px;
    min-height: 55px;
    padding: 5px;
    margin: 10px 5px 5px 8px;
  }
  .companyLogoMainInner img {
    width: 100%;
  }
  .totalHeading p {
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-device-width: 320px) {
  .bodyContentpaymentmethod .BodyContentTop {
    padding: 20px 5px 0px;
  }
  .companyLogoMain {
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
  }
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading {
    width: 100%;
  }
  .bodyContentpaymentmethod .paymentMethodNewCont .totalHeading p {
    text-align: center;
    font-size: 1rem !important;
  }
  .companyLogoMainInner img {
    width: 100%;
  }
  .companyLogoMainInner {
    width: 70px;
    min-height: 60px;
    padding: 5px;
    margin: 10px 5px 5px 10px;
  }
  .totalHeading p {
    font-size: 0.9rem !important;
  }
  .radioLablelBox {
    width: 50%;
  }
}
