footer {
  background: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid var(--lightGray);
  z-index: 99;
}
.footerLeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  padding: 12px 0;
}
.footerLeftSide p {
  color: #565656;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}
.footer_bottom_logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.footer_bottom_logo a {
  width: 100%;
}
.footer_bottom_logo span {
  width: 49%;
  float: left;
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}
.footer_bottom_logo span img {
  width: 100%;
}
@media screen and (max-width: 320px) {
  .footerLeftSide p {
    font-size: 0.7rem;
  }
}

@media only screen and (min-device-width: 321px) and (max-device-width: 515px) {
  .footerLeftSide p {
    font-size: 0.8rem;
  }
}
