body {
  font-family: "Roboto", sans-serif;
  background: #fff !important;
}
input:focus {
  box-shadow: none !important;
}
button:focus {
  box-shadow: none !important;
}
header {
  height: 60px;
}
header {
  min-height: 60px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12);
  background: #fff;
  z-index: 99;
}
.headerLogo {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headerLogo img {
  margin-left: -5px;
  width: 150px;
  position: relative;
  top: 2px;
}
.headerLogo p {
  margin: 0;
  font-size: 0.6rem;
  color: #3b0c36;
  position: relative;
  top: -2px;
  left: 28px;
}
a {
  text-decoration: none;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.headerRight {
  text-align: right;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.headerRight p {
  margin: 0;
  font-size: 0.8rem;
  color: #3b0c36;
  padding: 0;
}
.headerRight p a {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: #3b0c36;
  /* border: 1px solid #3B0C36; */
  border-radius: 50px;
  /* padding: 5px 5px; */
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 6px;
}
.gpIconMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.gpIcon {
  background: url("../../../assets/images/GP.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  /* margin-right: 5px;
    top: 2px; */
}
.appleIcon {
  background: url("../../../assets/images/apple_icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  margin-right: 1px;
  top: -1px;
}
.headerNewBanner {
  background: url("../../../assets/images/headerBannerDesktop.png") #410144;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-height: 110px;
  z-index: 999;
}
.headerNewBanner.headerwithoutBanner {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-height: 20px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 320px) {
  .headerRight p {
    font-size: 0.7rem;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 515px) {
  .headerRight p {
    font-size: 0.8rem;
  }
  .headerNewBanner {
    min-height: 60px;
    background: url("../../../assets/images/headerBannerMobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
